const MenuMobile = ({ onMobileMenuClick, menuIsOpen = false }) => {
  return (
    <div className="d-flex d-md-none">
      <button
        className={`hamburger hamburger--spring z-index-2 position-relative ${
          menuIsOpen ? "is-active" : false
        }`}
        onClick={onMobileMenuClick}>
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
    </div>
  );
};

export default MenuMobile;
