import { useRouter } from "next/router";
import { useAppContext } from "../../_context";
import { getTranslationStrings } from "../../utilities/Helpers";

const CountrySwitcher = () => {
  const router = useRouter();
  const [globalState, setGlobalState] = useAppContext();

  const onChange = ({ currentTarget }) => {
    const locale = currentTarget.value;
    setGlobalState({
      ...globalState,
      tx: getTranslationStrings(locale),
    });
    router.push(router.asPath, router.asPath, {
      locale,
    });
  };

  return (
    <select
      onChange={onChange}
      defaultValue={router.locale}
      className="px-3 py-2 border-grey-white-bg rounded bg-transparent">
      <option value="en">English</option>
      <option value="cz">čeština</option>
    </select>
  );
};

export default CountrySwitcher;
