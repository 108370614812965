import { useRouter } from "next/router";
import { getMobileDeeplinkKeys } from "../constants/mobileDeeplinkHelper";
import { getWebRoutes } from "../constants/routesHelper";

function useRouteHelper() {
  const router = useRouter();

  const { query, asPath, replace } = router;
  const defaultPath = asPath.substring(1);
  const universalLinkRoute = defaultPath;
  // we identify the mobile screen from the query params
  const mobileScreen = query?.from?.toLocaleLowerCase();

  const indexOfEndingPageNameSpecialCharacter =
    universalLinkRoute?.indexOf("?");

  const getRouteScreen = () => {
    if (indexOfEndingPageNameSpecialCharacter > -1) {
      // Get the page name before the params
      const routeName = universalLinkRoute.substring(
        0,
        indexOfEndingPageNameSpecialCharacter,
      );
      return routeName;
    }
    return universalLinkRoute;
  };

  const getRouteParams = () => {
    if (indexOfEndingPageNameSpecialCharacter > -1) {
      // Get the params after the page name
      const webParams = universalLinkRoute.substring(
        indexOfEndingPageNameSpecialCharacter + 1,
      );
      const indexOfParamsSeparatorCharacter = webParams?.indexOf("&");

      if (mobileScreen) {
        // we should skip the first param, which is the screen name identifier
        if (indexOfParamsSeparatorCharacter > -1) {
          return webParams.substring(indexOfParamsSeparatorCharacter + 1);
        }
        return;
      }

      return webParams;
    }

    return;
  };

  const getMobileFallbackScreenRoute = () => {
    const mobileFallbackScreenRoute = `/${
      getWebRoutes.mobileRoute
    }?from=${getRouteScreen()}`;

    if (getRouteParams()) {
      return `${mobileFallbackScreenRoute}&${getRouteParams()}`;
    }
    return mobileFallbackScreenRoute;
  };

  const getMobileDeeplinkRoute = () => {
    // we should only return something after we have the querry params
    // otherwise we would have conflicts with server/client side rendering
    if (mobileScreen) {
      const mobileDeeplinkRoute = `racketpal://${mobileScreen}`;

      if (getRouteParams()) {
        return `${mobileDeeplinkRoute}?${getRouteParams()}`;
      }
      return mobileDeeplinkRoute;
    }
    return;
  };

  const mobileDeeplinkRoutesArray = Object.values(getMobileDeeplinkKeys);
  const isMobileDeeplinkRoute = mobileDeeplinkRoutesArray.includes(
    getRouteScreen(),
  );

  return {
    replace,
    query,
    universalLinkRoute,
    getRouteScreen,
    getRouteParams,
    isMobileDeeplinkRoute,
    getMobileDeeplinkRoute,
    getMobileFallbackScreenRoute,
  };
}

export default useRouteHelper;
