import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Head from "next/head";
import { useAppContext } from "../_context";
import { useRouter } from "next/router";
import { isPartOfPremiumFunnel, getBodyClass } from "../utilities/Helpers";

const Template = ({ children }) => {
  const router = useRouter();

  const [{ tx }] = useAppContext();
  const bodyClass = getBodyClass(router.pathname);
  const title =
    "RacketPal" + (children.props.title ? ` - ${children.props.title}` : "");

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta
          name="facebook-domain-verification"
          content="71vm0inrun4hw71i8yjmi883tgklh8"
        />

        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:title" content={title} />
        <meta
          name="description"
          content={
            tx[
              "Find local racket sports partners in minutes. Install the app for Free today"
            ]
          }
        />
        <meta
          property="og:description"
          content={
            tx[
              "Find local racket sports partners in minutes. Install the app for Free today"
            ]
          }
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://racketpal.co.uk/img/rp-logo.png"
        />
      </Head>

      <Header />
      <main className={bodyClass}>{children}</main>
      {!isPartOfPremiumFunnel(router, "/") && <Footer />}
    </>
  );
};

export default Template;
