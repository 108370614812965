import Row from "react-bootstrap/Row";
import FooterBlock from "./FooterBlock";

const FooterLinks = ({ items, className = "" }) => {
  return (
    <Row className={className}>
      {items.map((item, index) => {
        return <FooterBlock key={index} block={item} />;
      })}
    </Row>
  );
};

export default FooterLinks;
