import { useAppContext } from "../_context";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "next/image";
import Button from "../components/Misc/Button";
import AppStoreLinks from "../components/Misc/AppStore/AppStoreLinks";
import Head from "next/head";
import useRouteHelper from "../hooks/useRouteHelper";
import { useEffect } from "react";

const Custom404 = () => {
  const [{ tx }] = useAppContext();

  const { replace, isMobileDeeplinkRoute, getMobileFallbackScreenRoute } =
    useRouteHelper();

  useEffect(() => {
    if (isMobileDeeplinkRoute) {
      replace(getMobileFallbackScreenRoute());
    }
  }, [isMobileDeeplinkRoute]);

  return (
    <>
      <Head>
        <title>RacketPal - 404</title>
      </Head>
      <section className="error-404-section d-flex align-items-center section-padding-y">
        <Container className="error-404-container">
          <Row className="w-100">
            <Col md={6} className="error-graphic mb-4 mb-md-0">
              <Image
                src="/img/404.png"
                width={720}
                height={408}
                unoptimized={true}
              />
            </Col>
            <Col md={6} className="d-flex align-items-center error-message">
              <div className="w-100 position-relative">
                <h1>
                  {
                    tx[
                      "Oops, this is awkward. Like being on court with no racket."
                    ]
                  }
                </h1>
                <h4 className="fw-normal mb-4">
                  {
                    tx[
                      "We can't find the page you're looking for, but you might find what you need in the RacketPal app."
                    ]
                  }
                </h4>
                <AppStoreLinks white={false} className="mb-4" />
                <Button href={"/"} className="mb-4">
                  {tx["Back to homepage"]}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Custom404;
