export const androidUrl =
  "https://play.google.com/store/apps/details?id=com.racketpal";

export const iOSUrl =
  "https://apps.apple.com/gb/app/racketpal-find-sport-partners/id1453817491";

export const availableSportNames = [
  "tennis",
  "badminton",
  "padel",
  "squash",
  "table-tennis",
  "pickleball",
];
