export const storageKeys = {
  userLocation: "userLocation",
  preferredMatchType: "preferredMatchType",
  userGender: "userGender",
  userLevel: "userLevel",
  userData: "userData",
  storedAlreadySeenOffer: "storedAlreadySeenOffer",
  remoteConfig: "remoteConfig",
  loggedUserToken: "loggedUserToken",
  userSubscriptionStatus: "userSubscriptionStatus",
};
