import ReactGa from "react-ga";
import { AnalyticsBrowser } from "@segment/analytics-next";

let ReactPixelRP = null;
let segment = null;
export const init = async () => {
  if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
    ReactGa.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS);
  }

  if (process.env.NEXT_PUBLIC_FACEBOOK_PIXEL) {
    const { default: ReactPixel } = await import("react-facebook-pixel");
    ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL, null, {
      autoConfig: true,
      debug: true,
    });
    ReactPixelRP = ReactPixel;
  }
  if (process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY) {
    let [response] = await AnalyticsBrowser.load({
      writeKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY,
    });
    segment = response;
  }
};

export const logPageView = (page) => {
  if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS && page) {
    ReactGa.pageview(page);
  }

  if (process.env.NEXT_PUBLIC_FACEBOOK_PIXEL && page) {
    ReactPixelRP && ReactPixelRP.pageView();
  }
  if (process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY && page) {
    segment && segment.page(page);
  }
};

export const track = (event, props) => {
  if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS && event) {
    ReactGa.event({
      category: "Website",
      action: `ga_${event}`,
    });
  }

  if (process.env.NEXT_PUBLIC_FACEBOOK_PIXEL && event) {
    ReactPixelRP && ReactPixelRP.trackCustom(`fb_${event}`);
  }

  if (process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY && event) {
    segment && segment.track(event, { ...props, source: "website" });
  }
};

export const identifyUser = (userId, traits) => {
  if (process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY && userId) {
    segment && segment.identify(userId, traits);
  }
};

export const addUserProps = (traits) => {
  if (process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY && traits) {
    segment && segment.identify(traits);
  }
};

export const events = {
  downloadApp: "download_app",
  seenPaywall: "seen_paywall",
  seenPlanOffer: "seen_[plan]_offer",
  clickedFullPrice: "clicked_full_price",
  clickedDiscountedPrice: "clicked_discounted_price",
  discountOverlay: "discount_overlay",
  startFunnel: "start_funnel",
  endFunnel: "end_funnel",
  viewShoppingCart: "view_shopping_cart",
  purchase: "purchase",
};
