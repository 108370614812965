import RacketPalLogo from "../RacketPalLogo";
import Container from "react-bootstrap/Container";
import Link from "next/link";
import HeaderLinks from "./HeaderLinks";
import { useAppContext } from "../../_context";
import { useRouter } from "next/router";
import MobileMenuItems from "./MobileMenuItems";
import {
  isPartOfAuthentication,
  isPartOfPremiumFunnel,
} from "../../utilities/Helpers";
import {
  clearUserSavedDataSessionStorage,
  initialUserState,
  useUserContext,
} from "../../_contextUser";

const Header = () => {
  const router = useRouter();
  const [globalState, setGlobalState] = useAppContext();
  const [_, setUserContext] = useUserContext();
  const { tx, mobileMenuOpen } = globalState;

  const removeUseravedData = () => {
    clearUserSavedDataSessionStorage();
    setUserContext((prev) => {
      return {
        ...prev,
        ...initialUserState,
      };
    });
  };

  const navbarCustomizationByLink = () => {
    if (
      router.pathname.includes("/already-subscribed") ||
      router.pathname.includes("/install-the-app")
    ) {
      return "d-inline d-md-none";
    }

    if (router.pathname.includes("/terms-and-conditions")) {
      return "printable-header";
    }

    return "";
  };

  const NavigationLinks = () => {
    if (isPartOfAuthentication(router)) {
      return (
        <button
          className="sign-up-button btn btn-white text-black fw-bold"
          onClick={removeUseravedData}>
          {tx["Log out"]}
        </button>
      );
    }

    if (isPartOfPremiumFunnel(router, "/")) {
      return <> </>;
    }
    if (isPartOfPremiumFunnel(router)) {
      return (
        <Link href="/find-tennis-partner/player-details" passHref={true}>
          <a className="sign-up-button btn-primary">{tx["Sign up"]}</a>
        </Link>
      );
    }

    return (
      <HeaderLinks
        items={headerLinks}
        onMobileMenuClick={onMobileMenuClick}
        mobileMenuOpened={mobileMenuOpen}
      />
    );
  };

  const Logo = () => {
    return (
      <Link href={"/"} passHref>
        <a>
          <RacketPalLogo />
        </a>
      </Link>
    );
  };

  const headerLinks = [
    { href: "/", label: tx["Players"] },
    { href: "/clubs", label: tx["Clubs"] },
    { href: "/coaches", label: tx["Coaches"] },
    { href: "/stringers", label: tx["Stringers"] },
    { href: "/about", label: tx["Our Story"] },
  ];

  const onMobileMenuClick = ({ currentTarget }) => {
    const isActive = currentTarget.classList.contains("is-active");

    if (isActive) {
      document.body.classList.remove("overflow-hidden"); // @todo How to do this react style
      setGlobalState({
        ...globalState,
        mobileMenuOpen: false,
      });
    } else {
      document.body.classList.add("overflow-hidden"); // @todo How to do this react style
      setGlobalState({
        ...globalState,
        mobileMenuOpen: true,
      });
    }
  };

  return (
    <header
      className={`${
        mobileMenuOpen ? "mobile-menu-open" : ""
      } ${navbarCustomizationByLink()}`}>
      <Container>
        <div className="d-flex flex-row align-items-center justify-content-between py-4 header-height">
          <Logo />

          <NavigationLinks />
        </div>
        <MobileMenuItems items={headerLinks} />
      </Container>
    </header>
  );
};

export default Header;
