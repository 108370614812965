import Template from "../components/Template";
import "../styles/app.scss";
import "swiper/css";

import { ContextWrapper } from "../_context";
import { ContextPremiumWrapper } from "../_contextPremium";
import { ContextUserWrapper } from "../_contextUser";

function RacketPal({ Component, pageProps }) {
  return (
    <ContextWrapper>
      <ContextPremiumWrapper>
        <ContextUserWrapper>
          <Template>
            <Component {...pageProps} />
          </Template>
        </ContextUserWrapper>
      </ContextPremiumWrapper>
    </ContextWrapper>
  );
}

export default RacketPal;
