import Error from "next/error";
import slugify from "slugify";
import { makeAspectCrop, centerCrop } from "react-image-crop";
import { createHash } from "crypto";

import Custom404 from "../pages/404";
import en from "../translations/en.json";
import cz from "../translations/cz.json";

const languages = {
  ...en,
  ...cz,
};

const base64toBlob = (base64Data, contentType = "image/jpeg") => {
  const sliceSize = 1024;
  const byteCharacters = window.atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const avatarThumbnailDimensions = {
  WIDTH: 300,
  HEIGHT: 300,
};

export const avatarDimensions = {
  WIDTH: 1024,
  HEIGHT: 1024,
};

export const photoThumbnailDimensions = {
  WIDTH: 448,
  HEIGHT: 252,
};

export const photoDimensions = {
  WIDTH: 1280,
  HEIGHT: 720,
};

export const apiUrl = (path) => {
  return (
    process.env.NEXT_PUBLIC_API_BASE.replace(/\/$/, "") +
    "/" +
    path.replace(/^\//, "")
  );
};

export const returnHtmlError = (status) => {
  switch (status) {
    case 404:
      return <Custom404 />;

    default:
      return <Error statusCode={status} />;
  }
};

export const query = (selector, all = false, element = document) => {
  const func = all ? "querySelectorAll" : "querySelector";
  return element[func](selector);
};

export const getBodyClass = (pathname) => {
  return `page-${slugify(pathname.replace(/\//g, "-"))}`;
};

export const getLinkToContentfulMedia = (record, field) => {
  if (record[field].fields)
    return `https:${record[field].fields.file.url.replace(/^http(s)?:/, "")}`;
};

export const getTranslationStrings = (locale) => {
  // const strings = new LocalizedStrings(languages);
  // strings.setLanguage(locale);
  const strings = languages[locale];

  return strings;
};

export const getPercent = (pct, locale) => {
  if (["cz"].includes(locale)) {
    return `${pct} %`;
  }

  return `${pct}%`;
};

export const centerAspectCrop = (mediaWidth, mediaHeight, aspect, unit = "%") =>
  centerCrop(
    makeAspectCrop(
      {
        unit,
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );

export const handleCropSubmit = async ({
  currentImage,
  completedCrop,
  avatarRatio = false,
  cb,
}) => {
  const maxWidth = avatarRatio ? avatarDimensions.WIDTH : photoDimensions.WIDTH;
  const maxHeight = avatarRatio
    ? avatarDimensions.HEIGHT
    : photoDimensions.HEIGHT;

  const canvas = document.createElement("canvas");
  const scaleX = currentImage.naturalWidth / currentImage.width;
  const scaleY = currentImage.naturalHeight / currentImage.height;
  const ctx = canvas.getContext("2d");
  // second canvas for image resizing
  const canvasCopy = document.createElement("canvas");
  const copyContext = canvasCopy.getContext("2d");

  canvas.width = completedCrop.width * scaleX;
  canvas.height = completedCrop.height * scaleY;
  ctx.drawImage(
    currentImage,
    completedCrop.x * scaleX,
    completedCrop.y * scaleY,
    completedCrop.width * scaleX,
    completedCrop.height * scaleY,
    0,
    0,
    completedCrop.width * scaleX,
    completedCrop.height * scaleY,
  );

  const croppedImageURL = canvas.toDataURL("image/png");
  const img = new Image();
  img.src = croppedImageURL;

  img.onload = () => {
    // determine the new ratio
    let ratio = 1;
    if (img.width > maxWidth) {
      ratio = maxWidth / img.width;
    } else if (img.height > maxHeight) {
      ratio = maxHeight / img.height;
    }

    // draw original image in second canvas
    canvasCopy.width = img.width;
    canvasCopy.height = img.height;
    copyContext.drawImage(img, 0, 0);

    // copy and resize second canvas to first canvas
    canvas.width = img.width * ratio;
    canvas.height = img.height * ratio;
    ctx.drawImage(
      canvasCopy,
      0,
      0,
      canvasCopy.width,
      canvasCopy.height,
      0,
      0,
      canvas.width,
      canvas.height,
    );
    cb(canvas.toDataURL("image/png"));
  };
  // we're sending the full size cropped image if resizing fails for whatever reason
  img.onerror = () => {
    cb(croppedImageURL);
  };
};

export const uploadClubPhoto = async ({
  file,
  fieldName = "photo",
  prefix,
  type = "png",
}) => {
  const formData = new FormData();
  const blob = base64toBlob(
    file.replace(/^data:image\/png;base64,/, ""),
    "image/jpeg",
  );

  formData.append(fieldName, blob, `${prefix}_photo.png`);

  return await fetch(
    apiUrl(`/api/v1/admin/addClubPhoto?prefix=${prefix}&type=${type}`),
    {
      method: "POST",
      body: formData,
      headers: {
        "X-Api-Version": "New",
        Authorization: sessionStorage.getItem("loggedUserToken"),
      },
    },
  );
};

export const uploadPhoto = async ({
  file,
  folder,
  fieldName = "photo",
  prefix,
  type = "png",
}) => {
  const formData = new FormData();
  const blob = base64toBlob(
    file.replace(/^data:image\/png;base64,/, ""),
    "image/jpeg",
  );

  formData.append(fieldName, blob, `${prefix}_photo.png`);

  return await fetch(
    apiUrl(`/api/v1/upload?folder=${folder}&prefix=${prefix}&type=${type}`),
    {
      method: "POST",
      body: formData,
      headers: {
        "X-Api-Version": "New",
      },
    },
  );
};

export const isPartOfPremiumFunnel = (router, querry = "") => {
  if (
    router.asPath.includes(`/find-tennis-partner${querry}`) ||
    router.asPath.includes("/already-subscribed") ||
    router.asPath.includes("/install-the-app") ||
    router.asPath.includes("/offer") ||
    router.asPath.includes("/verifyEmail")
  ) {
    return true;
  }
  return false;
};

export const isPartOfAuthentication = (router) => {
  if (router.asPath.includes("/find-tennis-partner/player/")) {
    return true;
  }
  return false;
};

export const getId = () => {
  const randomNum = Math.random().toString();
  return createHash("sha256").update(randomNum).digest("hex").slice(0, 24);
};
