import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FooterLinks from "./FooterLinks";
import Link from "next/link";
import CountrySwitcher from "../Misc/CountrySwitcher";
import { useAppContext } from "../../_context";
import { useRouter } from "next/router";

const Footer = () => {
  const router = useRouter();
  const [{ tx }] = useAppContext();

  const getFooterItems = () => {
    return [
      {
        title: tx["footer:Contact"],
        items: [
          {
            href: "mailto:support@racketpal.co.uk",
            label: "support@racketpal.co.uk",
            newPage: true,
          },
          { href: "/contact", label: tx["footer:Contact us"] },
          // CANCELATION-FUNNEL: comment it for now, we might use it later
          // { href: "/find-tennis-partner/login", label: tx["Login"] },
        ],
      },
      {
        title: tx["footer:About"],
        items: [
          { href: "/about", label: tx["footer:About us"] },
          { href: "/careers", label: tx["We're hiring"] },
        ],
      },
      {
        title: tx["Partners"],
        items: [
          { href: "/clubs", label: tx["Clubs"] },
          { href: "/coaches", label: tx["Coaches & sparring partners"] },
          { href: "/stringers", label: tx["Stringers"] },
        ],
      },
      {
        title: tx["Social"],
        items: [
          {
            href: "https://www.instagram.com/racketpal.app/",
            label: tx["Instagram"],
            newPage: true,
          },
          {
            href: "https://www.facebook.com/RacketPal/",
            label: tx["Facebook"],
            newPage: true,
          },
          {
            href: "https://twitter.com/racketpal",
            label: tx["Twitter"],
            newPage: true,
          },
          {
            href: "https://www.linkedin.com/company/racketpal-app",
            label: tx["LinkedIn"],
            newPage: true,
          },
        ],
      },
    ];
  };

  return (
    <footer
      className={`pb-5 ${
        router.pathname.includes("/terms-and-conditions")
          ? "printable-footer"
          : ""
      }`}>
      <Container>
        <FooterLinks
          items={getFooterItems()}
          className="border-bottom border-grey-white-bg mb-5"
        />
        <Row>
          <Col md={9}>
            <h6 className="mb-2">
              &copy; RacketPal {new Date().getFullYear()}.{" "}
              {tx["All Rights Reserved."]}
            </h6>
            <p className="small text-grey-1">
              <Link href="/privacy-policy" passHref>
                <a className="text-grey-1">{tx["Privacy Policy"]}</a>
              </Link>
              <span> | </span>
              <Link href="/terms-and-conditions" passHref>
                <a className="text-grey-1">{tx["Terms & Conditions"]}</a>
              </Link>
            </p>
          </Col>
          <Col md={3} className="d-md-flex align-middle">
            <div>
              <CountrySwitcher />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
