import { createContext, useContext, useEffect, useState } from "react";
import { storageKeys } from "./utilities/storageKeys";

const AppContext = createContext([{}, () => {}]);

export const initialPremiumState = {
  userData: {},
  membershipData: [],
  userToken: "",
  selectedMembership: null,
  paymentData: null,
};

export const clearPremiumSavedDataSessionStorage = () => {
  sessionStorage.removeItem(storageKeys.userData);
  sessionStorage.removeItem(storageKeys.storedAlreadySeenOffer);
};

export function ContextPremiumWrapper(props) {
  const [state, setState] = useState({
    ...initialPremiumState,
    loadContext: true,
  });

  useEffect(() => {
    const storedUserData = JSON.parse(
      sessionStorage.getItem(storageKeys.userData),
    );
    const storedAlreadySeenOffer = sessionStorage.getItem(
      storageKeys.storedAlreadySeenOffer,
    );

    setState((prev) => {
      return {
        ...prev,
        userData: storedUserData,
        membershipData: [],
        userToken: "",
        selectedMembership: null,
        paymentData: null,
        loadContext: false,
        userAlreadyOnboarded: false,
        alreadySeenOffer: storedAlreadySeenOffer,
      };
    });
  }, []);

  return (
    <AppContext.Provider value={[state, setState]}>
      {props.children}
    </AppContext.Provider>
  );
}

export function usePremiumContext() {
  return useContext(AppContext);
}
