// here we should add new routes that will redirect the user to the mobile fallback page
// it will be directlly compatible with the mobile deeplink routes (CRM)
export const getMobileDeeplinkKeys = {
  invites: "invites",
  mymatches: "mymatches",
  setupmatch: "setupmatch",
  "setupmatch/short": "setupmatch/short",
  home: "home",
  postmatch: "postmatch",
  whoviewedmyprofile: "whoviewedmyprofile",
  matches: "matches",
  players: "players",
  coaches: "coaches",
  chats: "chats",
  clubs: "clubs",
  leagues: "leagues",
  setupleague: "setupleague",
  myprofile: "myprofile",
  referral: "referral",
  suggestedactions: "suggestedactions",
  support: "support",
  notificationcenter: "notificationcenter",
  offers: "offers",
  membershipplans: "membershipplans",
  leagueexplanatory: "leagueexplanatory",
  mostcompatibleplayer: "mostcompatibleplayer",
  notificationsettings: "notificationsettings",
  survey: "survey",
  graceperiod: "graceperiod",
  premiummigrationannounce: "premiummigrationannounce",
  "clubs/signup": "clubs/signup",
  "coaches/signup": "coaches/signup",
  profilecompletion: "profilecompletion",
  addscoreoutsideapp: "addscoreoutsideapp",
  explore: "explore",
  communities: "communities",
  "communities/map": "communities/map",
  verifyemail: "verifyemail",
  matchviachat: "matchviachat",
  prepareyourmatch: "prepareyourmatch",
  matchinfogoals: "matchinfogoals",
  whenwouldyouplay: "whenwouldyouplay",
  features: "features",
  partnership: "partnership",
  addsports: "addsports",
  Leagues: "Leagues",
  profileCompletion: "profileCompletion",
  addScoreOutsideApp: "addScoreOutsideApp",
};

export const getMobileDeeplinkRoutes = {
  players: "racketpal://players",
  leagues: "racketpal://leagues",
  matches: "racketpal://matches",
  invites: "racketpal://invites",
};
