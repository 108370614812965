import { createContext, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { getTranslationStrings } from "./utilities/Helpers";
import * as Analitycs from "./utilities/Analitycs";
import * as Firebase from "./utilities/Firebase";

const AppContext = createContext([{}, () => {}]);

export function ContextWrapper(props) {
  const router = useRouter();

  const [state, setState] = useState({
    tx: getTranslationStrings(router.locale),
    locale: router.locale,
    mobileMenuOpen: false,
    ...props,
  });

  useEffect(() => {
    Analitycs.init();
    Firebase.init();
  }, []);

  useEffect(() => {
    Analitycs.logPageView(router.route);
  }, [router.route]);

  return (
    <AppContext.Provider value={[state, setState]}>
      {props.children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
