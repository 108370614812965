import AppleStore from "./AppleStore";
import PlayStore from "./PlayStore";
import AppleStoreFilled from "./AppleStoreFilled";
import PlayStoreFilled from "./PlayStoreFilled";

const AppStoreLinks = ({
  white = true,
  filled = false,
  stacked = false,
  className = "",
}) => {
  const displayClass = stacked ? "stacked" : "";

  return (
    <div className={`app-store-links ${className} ${displayClass}`}>
      {filled ? (
        <>
          <AppleStoreFilled className="me-2" />
          <PlayStoreFilled />
        </>
      ) : (
        <>
          <AppleStore white={white} className="me-2" />
          <PlayStore white={white} />
        </>
      )}
    </div>
  );
};

export default AppStoreLinks;
