import { createContext, useContext, useEffect, useState } from "react";
import { storageKeys } from "./utilities/storageKeys";
const { loggedUserToken } = storageKeys;

const AppContext = createContext([{}, () => {}]);

export const initialUserState = {
  idUser: null,
  userLoginToken: "",
  userDeactivated: false,
  userActiveMembership: null,
  userAvailableSubscriptions: [],
  userCancelationOffer: null,
};

export const clearUserSavedDataSessionStorage = () => {
  sessionStorage.removeItem(loggedUserToken);
};

export function ContextUserWrapper(props) {
  const [state, setState] = useState({
    ...initialUserState,
    loadUserContext: true,
  });

  useEffect(() => {
    const storedUserToken = sessionStorage.getItem(loggedUserToken);

    setState((prev) => {
      return {
        ...prev,
        userLoginToken: storedUserToken,
        loadUserContext: false,
      };
    });
  }, []);

  useEffect(() => {
    if (state.userActiveMembership?.status)
      sessionStorage.setItem(
        storageKeys.userSubscriptionStatus,
        state.userActiveMembership?.status,
      );
  }, [state.userActiveMembership?.status]);

  return (
    <AppContext.Provider value={[state, setState]}>
      {props.children}
    </AppContext.Provider>
  );
}

export function useUserContext() {
  return useContext(AppContext);
}
