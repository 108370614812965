import Link from "next/link";
import Col from "react-bootstrap/Col";

const FooterBlock = ({ block }) => {
  return (
    <Col md={6} lg={3} className="pb-5">
      <h6>{block.title}</h6>
      {block.items.map((item, index) => {
        if (item.newPage) {
          return (
            <p key={index} className="mb-1">
              <a
                className="text-grey-1 small"
                href={item.href}
                target="_blank"
                rel="noreferrer">
                {item.label}
              </a>
            </p>
          );
        }
        return (
          <p key={index} className="mb-1">
            <Link href={item.href} passHref>
              <a className="text-grey-1 small">{item.label}</a>
            </Link>
          </p>
        );
      })}
    </Col>
  );
};

export default FooterBlock;
