import Link from "next/link";
import { useRouter } from "next/router";

const MenuDesktop = ({ items }) => {
  const router = useRouter();

  return (
    <div className="d-none d-md-block">
      {items.map((item, index) => {
        const current = router.pathname === item.href;
        return current ? (
          <span
            key={index}
            className="fw-bold text-primary cursor-default small ms-4">
            {item.label}
          </span>
        ) : (
          <Link key={index} href={item.href} passHref>
            <a className="fw-bold small ms-4">{item.label}</a>
          </Link>
        );
      })}
    </div>
  );
};

export default MenuDesktop;
