// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getRemoteConfig,
  getAll,
  fetchAndActivate,
} from "firebase/remote-config";
import { addUserProps } from "./Analitycs";
import { storageKeys } from "./storageKeys";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

export const init = () => {
  const app = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfig(app);

  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
  fetchConfig(remoteConfig);
};

export const fetchConfig = (remoteConfig) => {
  fetchAndActivate(remoteConfig).then(() => {
    const segmentProps = {};
    const props = {};
    const config = getAll(remoteConfig);
    const values = Object.values(config);
    const keys = Object.keys(config);
    keys.forEach((key, index) => {
      segmentProps[`experiment_${key}`] = values[index]._value;
      props[key] = values[index]._value;
    });
    addUserProps(segmentProps);
    localStorage.setItem(storageKeys.remoteConfig, JSON.stringify(props));
  });
};
