import { CircularProgress } from "@material-ui/core";
import Link from "next/link";

const Button = ({
  children,
  href = null,
  onClick = null,
  color = "primary",
  textColor = "white",
  className = "",
  target = null,
  download = null,
  disabled = false,
  isLoading = false,
  newTab = false,
  type = undefined,
}) => {
  const buttonStyle = `rp-button btn btn-${color} ${
    disabled ? "opacity-50" : "opacity-100"
  } text-${textColor} fw-bold px-5 py-2 px-lg-7 py-lg-3 ${className}`;
  const opts = {
    onClick,
    className: buttonStyle,
    disabled,
    type,
  };
  const isAbsoluteLink = Boolean(href && href.match("://"));

  if (target !== null) {
    opts["target"] = target;
  }

  if (download !== null) {
    opts["download"] = download;
  }

  if (type !== null) {
    opts["type"] = type;
  }

  if (isAbsoluteLink) {
    opts["href"] = href;

    return (
      <a
        {...opts}
        target={newTab ? "_blank" : null}
        rel={newTab ? "noreferrer" : null}>
        {children}
      </a>
    );
  }

  return href ? (
    newTab ? (
      <a href={href} {...opts} target="_blank" rel="noreferrer">
        {children}
      </a>
    ) : (
      <Link href={href} passHref={true}>
        <a {...opts}>{children}</a>
      </Link>
    )
  ) : (
    <button {...opts}>
      {isLoading ? (
        <CircularProgress
          className="d-flex align-self-center justify-content-center w-100"
          color="inherit"
          size={20}
        />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
