import Nav from "react-bootstrap/Nav";
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import { useAppContext } from "../../_context";
import Link from "next/link";

const HeaderLinks = ({
  items,
  onMobileMenuClick,
  mobileMenuOpened = false,
}) => {
  const [{ tx }] = useAppContext();

  return (
    <Nav className="d-flex flex-row justify-content-end align-items-center">
      <MenuDesktop items={items} />
      <Link href="/find-tennis-partner/player-details" passHref={true}>
        <a className="sign-up-button btn-primary ms-md-4 ms-0 me-4 me-md-0">
          {tx["Sign up"]}
        </a>
      </Link>
      <MenuMobile
        onMobileMenuClick={onMobileMenuClick}
        menuIsOpen={mobileMenuOpened}
      />
    </Nav>
  );
};

export default HeaderLinks;
