import Row from "react-bootstrap/Row";
import Link from "next/link";
import { useRouter } from "next/router";
import { useAppContext } from "../../_context";

const MobileMenuItems = ({ items }) => {
  const router = useRouter();
  const [globalState, setGlobalState] = useAppContext();
  const closeMenu = () => {
    setGlobalState({
      ...globalState,
      mobileMenuOpen: false,
    });
    document.body.classList.remove("overflow-hidden"); // @todo How to do this react style
  };

  return (
    <Row className="py-4 overflow-auto">
      {items.map((item, index) => {
        const current = router.pathname === item.href;
        return (
          <p className="mb-4" key={index}>
            {current ? (
              <span
                className="fw-bold text-primary cursor-default small ms-4"
                onClick={closeMenu}>
                {item.label}
              </span>
            ) : (
              <Link href={item.href} passHref>
                <a className="fw-bold small ms-4" onClick={closeMenu}>
                  {item.label}
                </a>
              </Link>
            )}
          </p>
        );
      })}
    </Row>
  );
};

export default MobileMenuItems;
